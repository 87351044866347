import React, { useState } from 'react';
import styled from '@emotion/styled';
import CursorFollowingTooltip from './CursorFollowingTooltip';
import { BrowserView } from 'react-device-detect';

// Images
import healthFirst from '../img/healthFirst.png';
import mellifera from '../img/melliferaBeekeepersAndHoneyMakers.png';
import depot from '../img/Depot.png';
import panaderia from '../img/Panaderia.png';
import backpackTraveller from '../img/Backpack_Traveller.png';
import easymeals from '../img/easyMeals.png';
import cocco from '../img/Cocco.png';
import staffScout from '../img/Staffscout.png';
import joinUp from '../img/JoinUp.png';
import bridge from '../img/Bridge.png';
import justicia from '../img/Justicia.png';
import kastell from '../img/Kastell.png';

const latestWork = [
  {
    name: 'HealthFirst',
    image: healthFirst,
    link: 'https://healthfirst.qodeinteractive.com/landing/',
  },
  {
    name: 'Mellifera',
    image: mellifera,
    link: 'https://mellifera.qodeinteractive.com/landing/',
  },
  {
    name: 'Depot',
    image: depot,
    link: 'https://depot.qodeinteractive.com/landing-page/',
  },
  {
    name: 'Panaderia',
    image: panaderia,
    link: 'https://panaderia.qodeinteractive.com/landing/',
  },
  {
    name: 'Backpack Traveller',
    image: backpackTraveller,
    link: 'https://backpacktraveler.qodeinteractive.com/landing/',
  },
  {
    name: 'EasyMeals',
    image: easymeals,
    link: 'https://easymeals.qodeinteractive.com/landing/',
  },
  {
    name: 'JoinUp',
    image: joinUp,
    link: 'https://joinup.qodeinteractive.com/landing/',
  },
  {
    name: 'Staff Scout',
    image: staffScout,
    link: 'https://staffscout.qodeinteractive.com/landing/',
  },
  {
    name: 'Cocco',
    image: cocco,
    link: 'https://cocco.qodeinteractive.com/landing/',
  },
  {
    name: 'Bridge',
    image: bridge,
    link: 'https://bridgelanding.qodeinteractive.com/',
  },
  {
    name: 'Justicia',
    image: justicia,
    link: 'https://justicia.qodeinteractive.com/landing/',
  },
  {
    name: 'Kastell',
    image: kastell,
    link: 'https://kastell.qodeinteractive.com/landing/',
  },
];

const GalleryContainer = styled.div`
  :hover {
    .cursor-following-tooltip {
      opacity: 1;
    }
  }
`;
const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
`;
const Box = styled.a`
  width: 100%;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default function LatestWorkGallery() {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const updateCoordinates = (event) => {
    const { clientX, clientY } = event;
    setCoordinates({ x: clientX, y: clientY });
  };
  return (
    <GalleryContainer onMouseMove={updateCoordinates}>
      <BrowserView>
        <CursorFollowingTooltip coordinates={coordinates} />
      </BrowserView>
      <Gallery>
        {latestWork.map((item) => (
          <Box target="_blank" href={item.link} key={item.name}>
            <img src={item.image} title={item.name} alt={item.name} />
          </Box>
        ))}
      </Gallery>
    </GalleryContainer>
  );
}
