import React from 'react';
import styled from '@emotion/styled';
import './App.css';
import LatestWorkGallery from './components/LatestWorkGallery';
import Logo from './components/Logo';
import { Link, NavLink } from './components/Link';
import LinkList from './components/LinkList';
import List from './components/List';
import CV_PDF from './CV_Marina_Pavlovic.pdf';

const Header = styled.header`
  display: flex;
  align-items: top;
  justify-content: space-between;
  padding: 3rem;
  & div:nth-of-type(2) {
    height: 5rem;
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 550px) {
    align-items: flex-start;
    & div:nth-of-type(2) {
      height: 100%;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 1rem;
      a {
        margin: 0 0 2rem 0;
      }
    }
  }
`;
const Hero = styled.section`
  padding-top: 6.5rem;
  margin: 0 auto;
  max-width: calc(var(--grid-width) - ((var(--grid-width) / 12) * 1));
  padding-left: var(--left-margin);
  padding-right: var(--left-margin);
  div {
    margin-top: 6rem;
  }
  h1 {
    max-width: 100%;
    max-width: calc(var(--grid-width) - ((var(--grid-width) / 12) * 3.5));
    font-size: 7rem;
    line-height: 8.8rem;
  }
  p,
  a {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 3.3rem;
  }
  span {
    display: block;
  }
  @media only screen and (max-width: 550px) {
    h1 {
      font-size: 4rem;
      line-height: 5.8rem;
    }
  }
  @media only screen and (max-width: 950px) {
    span {
      display: contents;
    }
  }
`;
const LatestWork = styled.section`
  max-width: var(--grid-width);
  padding-top: 16rem;
  margin: 0 auto;
`;
const Contact = styled.section`
  max-width: var(--grid-width);
  margin: 0 auto;
  padding-top: 16rem;
  div {
    padding-left: var(--left-margin);
    background-color: var(--color-1);
  }
  h2 {
    display: block;
    max-width: calc(var(--grid-width) - ((var(--grid-width) / 12) * 8));
    font-size: 4rem;
    line-height: 5rem;
    padding-top: 8rem;
  }
  > div > div:nth-of-type(1) {
    display: block;
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 0;
  }
  a {
    line-height: 3.3rem;
    font-size: 1.7rem;
    font-weight: 400;
    color: var(--color-6);
  }
  @media only screen and (max-width: 1400px) {
    max-width: 90%;
    div {
      padding-left: var(--left-margin);
    }
  }
`;
const Bio = styled.section`
  max-width: calc(var(--grid-width) - ((var(--grid-width) / 12) * 1));
  padding-left: var(--left-margin);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 9.5rem;
  h3 {
    letter-spacing: 0.2rem;
    padding-bottom: 3rem;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1.8rem;
    color: var(--color-2);
    font-weight: 500;
    text-transform: uppercase;
  }
  & div h3:nth-of-type(2) {
    padding-top: 2.8rem;
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
    & div h3:nth-of-type(1) {
      padding-top: 2.8rem;
    }
  }
`;
const Skills = styled.section`
  max-width: calc(var(--grid-width) - ((var(--grid-width) / 12) * 1));
  padding-left: var(--left-margin);
  margin: 0 auto;
  padding-top: 5rem;
  h3 {
    padding-bottom: 3rem;
    letter-spacing: 0.2rem;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1.8rem;
    color: var(--color-2);
    font-weight: 500;
    text-transform: uppercase;
  }
`;
const Footer = styled.footer`
  max-width: calc(var(--grid-width) - calc((var(--grid-width) / 12) * 1));
  padding-left: var(--left-margin);
  padding-right: var(--left-margin);
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 3rem;
  padding-top: 5rem;
  & > div:nth-of-type(1) a {
    margin: 3rem;
  }
  h1 {
    font-family: 'Signerica Fat', serif;
    margin-left: 3.5rem;
  }
  @media only screen and (max-width: 1400px) {
    & > div:nth-of-type(2) {
      padding-right: 3rem;
    }
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 0;
    & div:nth-of-type(2) {
      margin-bottom: 5rem;
    }
    h1 {
      text-align: center;
    }
  }
  @media only screen and (max-width: 550px) {
    & a {
      display: block;
      margin: 3rem 1.5rem !important;
    }
  }
`;

function App() {
  return (
    <>
      <Header>
        <div>
          <Logo />
        </div>
        <LinkList>
          <NavLink target="_blank" rel="noopener noreferrer" href={CV_PDF}>
            download resume
          </NavLink>
          <NavLink href="mailto:design@marinapavlovic.com">contact</NavLink>
        </LinkList>
      </Header>
      <Hero>
        <h1>Hi! I'm Marina - UI/UX designer based in Belgrade</h1>
        <div>
          <p>
            <span>
              Currently working at{' '}
              <Link href="https://qodeinteractive.com/">Qode Interactive</Link>{' '}
              but available for freelance work. &nbsp;
            </span>
            <span>
              I post very often on{' '}
              <Link href="https://dribbble.com/marinapavlovic">dribbble</Link>{' '}
              so you can follow my work.
            </span>
          </p>
        </div>
      </Hero>
      <LatestWork>
        <LatestWorkGallery />
      </LatestWork>
      <Contact>
        <div>
          <h2>Let's work together on your next project.</h2>
          <div>
            <Link href="mailto:design@marinapavlovic.com">
              design@marinapavlovic.com
            </Link>
          </div>
        </div>
      </Contact>
      <Bio>
        <div>
          <h3>Work Experience</h3>
          <List>
            <li>
              <p>Qode Interactive (2014 - Present)</p>
              <p>Senior Web Designer</p>
            </li>
            <li>
              <p>ITAF ICT Services (2012 - 2014)</p>
              <p>Web and Graphic designer</p>
            </li>
            <li>
              <p>New Moment New Ideas Company (2009 - 2012)</p>
              <p>Graphic Designer</p>
            </li>
            <li>
              <p>Freelance (2004 - Present)</p>
              <p>Web and Graphic Designer</p>
            </li>
          </List>
          <h3>Education</h3>
          <List>
            <li>
              <p>Senior College of Fine and Applied Arts</p>
              <p>CLASS OF 2009; GRAPHIC DESIGN</p>
            </li>
            <li>
              <p>High School for Design</p>
              <p>CLASS of 2006; PACKAGING DESIGN</p>
            </li>
          </List>
        </div>
        <div>
          <h3>Interviews & Articles</h3>
          <List>
            <li>
              <p>
                <a
                  href="https://www.templatemonster.com/blog/experts-predict-technological-changes-web-design/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="38 Experts Predict Technological Changes In Web Design"
                >
                  38 Experts Predict Technological Changes In Web Design
                </a>
              </p>
              <p>MonsterPost</p>
            </li>
            <li>
              <p>
                <a
                  href="https://uradi-zaradi.rs/blog/izaberi-hobi-umesto-premanja-kuce/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Šta ja kao UI/UX dizajner radim i kako punim baterije"
                >
                  Šta ja kao UI/UX dizajner radim i kako punim baterije
                </a>
              </p>
              <p>Uradi Zaradi Blog</p>
            </li>
            <li>
              <p>
                <a
                  href="https://qodeinteractive.com/magazine/beginner-web-design-tips/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="10 Practical Web Design Tips for Beginners"
                >
                  10 Practical Web Design Tips for Beginners
                </a>
              </p>
              <p>Qode Magazine</p>
            </li>
            <li>
              <p>
                <a
                  href="https://qodeinteractive.com/magazine/who-to-follow-on-dribbble/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Top 20 Inspirational Designers Worth Following On Dribbble"
                >
                  Top 20 Inspirational Designers Worth Following On Dribbble
                </a>
              </p>
              <p>Qode Magazine</p>
            </li>
            <li>
              <p>
                <a
                  href="https://qodeinteractive.com/magazine/websites-for-downloading-illustrations/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="13 Best Websites for Downloading Illustrations"
                >
                  13 Best Websites for Downloading Illustrations
                </a>
              </p>
              <p>Qode Magazine</p>
            </li>
            <li>
              <p>
                <a
                  href="https://qodeinteractive.com/magazine/websites-for-downloading-mockups/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="15+ Best Sites to Download Mockups From"
                >
                  15+ Best Sites to Download Mockups From
                </a>
              </p>
              <p>Qode Magazine</p>
            </li>
            <li>
              <p>
                <a
                  href="https://qodeinteractive.com/magazine/top-design-resources/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Top 10+ Free and Paid Design Resources"
                >
                  Top 10+ Free and Paid Design Resources
                </a>
              </p>
              <p>Qode Magazine</p>
            </li>
          </List>
        </div>
      </Bio>
      <Skills>
        <div>
          <h3>Skills</h3>
          <List>
            <li>
              <p>Sketch</p>
            </li>
            <li>
              <p>Photoshop</p>
            </li>
            <li>
              <p>Figma</p>
            </li>
            <li>
              <p>Adobe XD</p>
            </li>
            <li>
              <p>Illustrator</p>
            </li>
            <li>
              <p>Invision</p>
            </li>
            <li>
              <p>Zeplin</p>
            </li>
          </List>
        </div>
      </Skills>
      <Footer>
        <div>
          <h1>Marina Pavlovic</h1>
          <LinkList>
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://dribbble.com/marinapavlovic"
            >
              Dribbble
            </NavLink>
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/marina_ui_ux/"
            >
              Instagram
            </NavLink>
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/marinapavlovic/"
            >
              LinkedIn
            </NavLink>
          </LinkList>
        </div>
        <div>
          <Logo />
        </div>
      </Footer>
    </>
  );
}

export default App;
