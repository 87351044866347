import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  transition: opacity 0.7s;
  opacity: 0;
  position: fixed;
  top: calc(${props => props.top}px - 2rem);
  left: calc(${props => props.left}px + 4rem);
  width: 15rem;
  height: 5rem;
  background-color: silver;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-1);
  p {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1rem;
    color: var(--color-2);
    letter-spacing: 0.2rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

function CursorFollowingTooltip({ coordinates }) {
  return (
    <Container
      top={coordinates.y}
      left={coordinates.x}
      className="cursor-following-tooltip"
    >
      <p>My Latest Work</p>
    </Container>
  );
}

export default CursorFollowingTooltip;
