import React from 'react';
import styled from '@emotion/styled';

import logoImage from '../img/Logo_M_large.png';
import logoText from '../img/Logo_tekst_large.png';

const Container = styled.div`
  width: 13.5rem;
  height: 13.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const LogoImage = styled.img`
  margin-bottom: 0.8rem;
  width: 6.5rem;
  height: 4.8rem;
`;
const LogoText = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: -webkit-transform var(--logo-rotation-duration) linear;
  transition: transform var(--logo-rotation-duration) linear;
  &:hover {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
export default function Logo() {
  return (
    <Container>
      <LogoImage src={logoImage} title="Logo" alt="Logo" />
      <LogoText src={logoText} title="UI/UX Designer" alt="UI/UX Designer" />
    </Container>
  );
}
