import styled from '@emotion/styled';

export const Link = styled.a`
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-family: 'IBM Plex Mono';
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--color-2);
  &:hover {
    text-decoration: none;
    :after {
      width: 100%;
    }
  }
  :after {
    width: 0;
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: var(--color-2);
    bottom: 0;
    left: 0;
    -webkit-transition: width 0.32s ease-in-out;
    -o-transition: width 0.32s ease-in-out;
    transition: width 0.32s ease-in-out;
  }
`;
export const NavLink = styled(Link)`
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`;
