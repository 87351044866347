import styled from '@emotion/styled';

const List = styled.ul`
  padding: 0 0 0 1rem;
  margin: 0;
  li {
    & a {
      text-decoration: none;
    }
    padding-bottom: 3.3rem;
  }
  li p:nth-of-type(1) {
    font-size: 1.7rem;
  }
  li p:nth-of-type(2) {
    padding-top: 1rem;
    font-size: 1.3rem;
    color: var(--color-7);
  }
`;

export default List;
