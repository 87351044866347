import styled from '@emotion/styled';

const LinkList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    display: block;
    margin: 3.5rem;
  }
`;
export default LinkList;
